export const DARK_BLUE = "#353F54"
export const PRESSED_DARK_BLUE = "#5D6E93"
export const DARK_BLUE_OUTLINE = "#2E3C5B"

export const WHITE_BACKGROUND = "#F2F6FD"

export const GREY = "#C2C5CC"
export const LIGHT_GREY = "#E1E2E5"
export const DARK_GREY = "#5D6576"

export const RED = "#DF411F"

export const HOVER_COLOR = "#EFF1F4"
