import React from "react"
import styled from "styled-components"

import Header from "./header"
import Footer from "./Footer"
import { MAX_MOBILE_WIDTH } from "../constants/sizes"

import "./layout.css"

const LayoutWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const Main = styled.main<{ skipPadding?: boolean }>`
  flex-grow: 1;
  padding: ${p => (p.skipPadding ? "0" : "3rem 0")};

  @media only screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    padding: 1rem 0;
  }
`

interface ISectionProps {
  skipPadding?: boolean
  skipMargin?: boolean
}
export const Section = styled.section<ISectionProps>`
  padding: ${p => (p.skipPadding ? "0" : "3.557rem 7rem")};
  max-width: ${p => (p.skipPadding ? "none" : "1500px")};

  margin-left: ${p => (p.skipMargin ? "0" : "auto")};
  margin-right: ${p => (p.skipMargin ? "0" : "auto")};

  @media only screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    padding: ${p => (p.skipMargin ? "0" : "2rem 1rem")};

    max-width: 500px;

    margin-left: ${p => (p.skipMargin ? "0" : "auto")};
    margin-right: ${p => (p.skipMargin ? "0" : "auto")};
  }
`

interface ILayoutProps {
  skipHeader?: boolean
  skipPadding?: boolean
}
const Layout = ({
  children,
  skipHeader = false,
  skipPadding = false,
}: React.PropsWithChildren<ILayoutProps>) => {
  return (
    <LayoutWrapper>
      {!skipHeader && <Header />}
      <Main {...{ skipPadding }}>{children}</Main>
      <Footer />
    </LayoutWrapper>
  )
}

export default Layout
