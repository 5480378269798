import React, { CSSProperties } from "react"
import styled from "styled-components"
import { Link } from "gatsby-plugin-intl"

import { DARK_BLUE, GREY } from "../constants/colors"

export const SubmitButton = styled.input`
  background: ${DARK_BLUE};
  color: white;
  transition: all 0.3s ease-in-out;

  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;

  padding: 10px 32px;
  margin: 0.3em;

  border-radius: 5px;
  border: none;

  :hover {
    text-decoration: none;
    background: #5d6e93;
    color: white;
  }
  :visited {
    color: white;
  }
  :disabled {
    background: ${GREY};
    color: white;
  }
`

type IButtonType = "primary" | "secondary"

interface LinkWrapperProps {
  type: IButtonType
  elevated?: "yes" // boolean creates an error on the console
}
const LinkWrapper = styled(Link)<LinkWrapperProps>`
  display: inline-block;

  background: ${p => (p.type === "primary" ? DARK_BLUE : "white")};
  box-shadow: ${p =>
    p.elevated === "yes" ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "none"};
  color: ${p => (p.type === "primary" ? "white" : DARK_BLUE)};
  transition: all 0.3s ease-in-out;

  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;

  padding: 10px 32px;
  margin: 0.3em;

  border-radius: 5px;
  border: ${p => (p.type === "primary" ? "none" : "1px solid #2E3C5B")};

  :hover {
    text-decoration: none;
    background: ${p => (p.type === "primary" ? "#5D6E93" : "#EFF1F4")};
    color: ${p => (p.type === "primary" ? "white" : DARK_BLUE)};
  }
  :visited {
    color: ${p => (p.type === "primary" ? "white" : DARK_BLUE)};
  }
  :disabled {
    background: ${p => (p.type === "primary" ? GREY : "white")};
    border: ${p => (p.type === "primary" ? "none" : `1px solid ${GREY}`)};
    color: ${p => (p.type === "primary" ? "white" : GREY)};
  }
`

interface IProps {
  className?: string
  elevated?: boolean
  style?: CSSProperties
  to: string
  type?: IButtonType
}

export default function Button({
  children,
  className,
  elevated = false,
  style,
  to,
  type = "primary",
}: React.PropsWithChildren<IProps>) {
  return (
    <LinkWrapper
      {...{ className }}
      {...{ style }}
      {...{ to }}
      {...{ type }}
      elevated={elevated ? "yes" : undefined}
    >
      {children}
    </LinkWrapper>
  )
}
