import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import Columns, { Column } from "./Columns"
import Language from "./language"
import ContactUs from "./contactUs"
import { Divider } from "./Divider"
import { MAX_MOBILE_WIDTH } from "../constants/sizes"

const FooterWrapper = styled.footer`
  width: 100%;
  background: #fafafa;
  padding: 3rem 3rem;
  @media only screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    padding: 1rem;
  }
`

const FooterLinks = styled.div`
  display: flex;
  a {
    margin-right: 2rem;
  }
`

const Footer = () => {
  const intl = useIntl()

  return (
    <FooterWrapper>
      <Columns noPadding>
        <Column>
          <ContactUs />
        </Column>
        <Column>
          <Language />
        </Column>
      </Columns>
      <Divider />
      <FooterLinks>
        <a href={intl.formatMessage({ id: "pages.privacy" })}>
          {intl.formatMessage({ id: "footer.privacy" })}
        </a>
        <a href={intl.formatMessage({ id: "pages.stridecode" })}>
          {intl.formatMessage({ id: "footer.stridecode" })}
        </a>
      </FooterLinks>
    </FooterWrapper>
  )
}

export default Footer
