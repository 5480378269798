import styled from "styled-components"

import { MIN_DESKTOP_WIDTH, MAX_MOBILE_WIDTH } from "../constants/sizes"

interface IProps {
  desktopReversed?: boolean
  noPadding?: boolean
  floating?: boolean
}

export default styled.div<IProps>`
  display: flex;
  flex-direction: ${p => (p.desktopReversed ? "row-reverse" : "row")};

  @media only screen and (min-width: ${MIN_DESKTOP_WIDTH}) {
    > div:first-child {
      padding-right: ${p => (p.noPadding || p.desktopReversed ? "0" : "6rem")};
      padding-left: ${p => (p.noPadding || !p.desktopReversed ? "0" : "6rem")};
    }
    > div:last-child {
      padding-right: ${p => (p.noPadding || !p.desktopReversed ? "0" : "6rem")};
      padding-left: ${p => (p.noPadding || p.desktopReversed ? "0" : "6rem")};
    }
  }

  ${({ floating }) =>
    floating &&
    `
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70vw;
    margin-left: -35vw;
    height: 65vh;
    margin-top: -32.5vh;
    max-width: 80vw;
  `}

  @media only screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    display: flex;
    flex-direction: column;
    position: inherit;
    width: 100%;
    padding: ${p => (p.noPadding ? "0" : "1rem 1em")};
    margin: 0;
    height: auto;
    max-width: 100%;

    > div:first-child {
      margin-bottom: 24px;
    }
  }
`

export const Column = styled.div`
  flex: 1;
`
