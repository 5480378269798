import React, { useState, useEffect } from "react"
import { Link, useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { Link as GatsbyLink } from "gatsby"
import styled from "styled-components"
import { Menu, MenuList, MenuButton, MenuLink } from "@reach/menu-button"
import "@reach/menu-button/styles.css"

import { DARK_BLUE } from "../constants/colors"

import SilbatoLogo from "./images/SilbatoLogo"
import Button from "./Button"
import ContactUs from "./contactUs"

const HamburgerOpen = require("../images/hamburger.svg")
const HamburgerClosed = require("../images/hamburger_closed.svg")

const HEADER_BUTTON_WIDTH = "150px"

interface IMenuItem {
  id: string
  children?: Array<IMenuItem>
}
const MENU_ITEMS: IMenuItem[] = [
  { id: "addons", children: [{ id: "horn" }, { id: "device" }] },
  { id: "pricing" },
  { id: "download" },
]

interface IHeaderRootProps {
  elevated: boolean
}
const HeaderRoot = styled.header<IHeaderRootProps>`
  position: sticky;

  background: white;
  box-shadow: ${p => (p.elevated ? "0px 4px 4px rgba(0, 0, 0, 0.15)" : "none")};
  transition: all 0.3s ease-in-out;

  top: 0;
  height: 6rem;
  z-index: 100;
`

const HEADER_SAFE_SPACE = 90
const MobileMenu = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  grid-template-columns: [left-gutter] 1fr [content] 12fr [right-gutter] 1fr;
  width: 100%;
  height: calc(100% - ${HEADER_SAFE_SPACE}px);
  position: fixed;
  top: ${HEADER_SAFE_SPACE}px;
  left: 0;
  background-color: #ffffff;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  overflow-y: auto;
  opacity: ${p => (p.active ? 1 : 0)};
  visibility: ${p => (p.active ? "visible" : "hidden")};
`

const MobileList = styled.div`
  padding: 35px 20px 20px;
`
const MobileItem = styled.li<{ isChild: boolean }>`
  width: 100%;
  list-style: none;
  a {
    padding-left: ${p => (p.isChild ? "20px" : "0")};
    text-align: left;
    width: auto;
    margin-left: 0;
  }
`

const HeaderWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 span {
    color: #df411f;
    :hover {
      text-decoration-color: #df411f;
    }
  }
`

const ChildrenHeader = styled.div`
  padding: 15px 0 0;
`

const HeaderTextButton = styled(Link)`
  display: inline-block;
  width: ${HEADER_BUTTON_WIDTH};

  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  color: ${DARK_BLUE};

  padding: 12px;

  :hover {
    color: ${DARK_BLUE};
    font-weight: 600;
  }

  :visited {
    color: ${DARK_BLUE};
  }
`

const Header = () => {
  const intl = useIntl()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [hasScrolled, setHasScrolled] = useState(false)

  useEffect(() => {
    const onScroll = (e: any) => {
      setHasScrolled(
        e.target?.documentElement?.scrollTop &&
          e.target.documentElement.scrollTop > 20
      )
    }
    window.addEventListener("scroll", onScroll)

    return () => window.removeEventListener("scroll", onScroll)
  }, [setHasScrolled])

  const handleMenuButton = () => setMobileMenuOpen(!mobileMenuOpen)

  const renderDesktopMenuItems = () => {
    return MENU_ITEMS.map(item => {
      if (item.children) {
        return (
          <Menu key={item.id}>
            <MenuButton>
              <FormattedMessage id={`header.${item.id}`} />{" "}
              <span aria-hidden>▾</span>
            </MenuButton>
            <MenuList>
              {item.children.map(child => {
                return (
                  <MenuLink
                    key={child.id}
                    as={GatsbyLink}
                    to={`/${intl.locale}${intl.formatMessage({
                      id: `pages.${child.id}`,
                    })}`}
                  >
                    {intl.formatMessage({ id: `header.${child.id}` })}
                  </MenuLink>
                )
              })}
            </MenuList>
          </Menu>
        )
      }
      return (
        <HeaderTextButton
          key={item.id}
          className="header-button"
          to={intl.formatMessage({ id: `pages.${item.id}` })}
        >
          <FormattedMessage id={`header.${item.id}`} />
        </HeaderTextButton>
      )
    })
  }

  const renderMobileMenuItems = () => {
    const renderItem = (item: IMenuItem, isChild = false) => (
      <MobileItem isChild={isChild} key={item.id}>
        <HeaderTextButton
          className="header-button"
          to={intl.formatMessage({ id: `pages.${item.id}` })}
        >
          <FormattedMessage id={`header.${item.id}`} />
        </HeaderTextButton>
      </MobileItem>
    )
    return MENU_ITEMS.map(item => {
      if (item.children) {
        return (
          <div key={item.id}>
            <ChildrenHeader>
              <FormattedMessage id={`header.${item.id}`} />{" "}
              <span aria-hidden>▾</span>
            </ChildrenHeader>
            {item.children?.map(i => renderItem(i, true))}
          </div>
        )
      }
      return renderItem(item)
    })
  }

  return (
    <HeaderRoot elevated={hasScrolled}>
      <HeaderWrapper>
        {/* Logo */}
        <h3 style={{ margin: 0 }}>
          <Link
            className="header-name"
            to="/"
            style={{ display: "flex", alignItems: "center" }}
          >
            <SilbatoLogo style={{ width: 50, marginRight: "1.09em" }} />
            <span className="mobile-hidden">
              {intl.formatMessage({ id: "appName" })}
            </span>
          </Link>
        </h3>

        {/* Mobile Menu */}
        <button
          onClick={handleMenuButton}
          className="desktop-hidden"
          style={{ width: "24px", height: "24px", marginRight: "1em" }}
        >
          <img
            src={mobileMenuOpen ? HamburgerClosed : HamburgerOpen}
            style={{ margin: 0 }}
          />
        </button>

        <MobileMenu active={mobileMenuOpen} className="desktop-hidden">
          <MobileList>{renderMobileMenuItems()}</MobileList>
          <div style={{ textAlign: "center", flexGrow: 1 }}>
            <Button
              elevated={false}
              to={intl.formatMessage({ id: "pages.quote" })}
              className="get-free-quote header"
            >
              {intl.formatMessage({ id: "header.quote" })}
            </Button>
          </div>
          <div style={{ background: "#fafafa", padding: "1em" }}>
            <ContactUs />
          </div>
        </MobileMenu>

        {/* Desktop Menu */}
        <div
          style={{ display: "flex", alignItems: "center" }}
          className="mobile-hidden"
        >
          {renderDesktopMenuItems()}
          <Button
            to={intl.formatMessage({ id: "pages.quote" })}
            style={{ marginLeft: "1em" }}
            elevated={!hasScrolled}
            className="get-free-quote header"
          >
            {intl.formatMessage({ id: "header.quote" })}
          </Button>
        </div>
      </HeaderWrapper>
    </HeaderRoot>
  )
}

export default Header
