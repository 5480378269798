import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import styled from "styled-components"

import { MAX_MOBILE_WIDTH } from "../constants/sizes"

type ILanguage = "es" | "en"

const languageName: { [language in ILanguage]: string } = {
  en: "English",
  es: "Español",
}

const Wrapper = styled.div`
  text-align: end;
  @media only screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    margin-top: 2rem;
    text-align: left;
  }
`

interface IIntProps {
  languages: ILanguage[]
  language: ILanguage
}

const selectedStyle: React.CSSProperties = {
  fontWeight: 600,
}
const unselectedStyle: React.CSSProperties = {
  textDecoration: `underline`,
}

const Language = () => {
  return (
    <Wrapper>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }: IIntProps) =>
          languages.map((language, index) => (
            <span key={language}>
              <a
                className="language"
                onClick={() => changeLocale(language)}
                style={{
                  color: `##5D6576`,
                  fontSize: "1em",
                  cursor: `pointer`,
                  ...(currentLocale === language
                    ? selectedStyle
                    : unselectedStyle),
                }}
              >
                {languageName[language]}
              </a>
              {index < languages.length - 1 ? " / " : ""}
            </span>
          ))
        }
      </IntlContextConsumer>
    </Wrapper>
  )
}

export default Language
