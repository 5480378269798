import React from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"

import WhatsAppLogo from "./images/WhatsAppLogo"
import Email from "./images/Email"
import styled from "styled-components"

const Wrapper = styled.div`
  > div {
    margin-bottom: 1rem;
  }

  > div:last-child {
    margin-bottom: 0;
  }
`
const ContactItem = styled.div`
  display: flex;
  align-items: center;
  div {
    width: 24;
    height: 24;
  }
`

export default function ContactUs() {
  const intl = useIntl()

  return (
    <Wrapper>
      <ContactItem>
        <p style={{ marginBottom: 0 }} className="body2">
          <FormattedMessage id="adwords.contact" />
        </p>
      </ContactItem>
      <ContactItem>
        <WhatsAppLogo style={{ width: 24, height: 24 }} />{" "}
        <p className="body2" style={{ marginBottom: 0 }}>
          &nbsp;WhatsApp:&nbsp;
          <a href="https://wa.me/16266672327" className="phone-contact">
            +1 626-667-2327
          </a>
        </p>
      </ContactItem>
      <ContactItem>
        <Email style={{ width: 24, height: 24 }} />{" "}
        <p className="body2" style={{ marginBottom: 0 }}>
          &nbsp;Email:&nbsp;
          <a
            href={`mailto:${
              intl.locale === "es"
                ? "hola@silbato.app"
                : "whistle@stridecode.com"
            }`}
            className="email-contact"
          >
            {intl.locale === "es"
              ? "hola@silbato.app"
              : "whistle@stridecode.com"}
          </a>
        </p>
      </ContactItem>
    </Wrapper>
  )
}
